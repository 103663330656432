/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React from 'react'
import title from '../images/title.png'

function Header() {
  return (
    <div>
      <div css={headerArea}>
        <img css={img} src={title} alt="" />
      </div>
    </div>
  )
}

export default Header

const headerArea = css`
 text-align: center;
`;

const img = css`
  width: 35%;
`