/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import { MemberList } from '../components/molecules/Question';
import { css } from '@emotion/react';
import { Link, useLocation } from 'react-router-dom';
import backImg from '../images/backImg02.jpeg'

// メンバーの写真
import simada from '../images/shimada.jpg';
import furuno from '../images/IMG_0037.JPG.jpg';
import sakamoto from '../images/S__44605442.jpg';
import asikawa from '../images/1686113961800.jpg';
import yamanaka from '../images/Untitled.png';

type memberImg = {
  img: string;
};

const imgList: memberImg[] = [
  { img: simada },
  { img: furuno },
  { img: sakamoto },
  { img: asikawa },
  { img: yamanaka },
];

const Answer = () => {
  const location = useLocation();
  const index = location.state.state - 1;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <div css={body} key={index}>
      <Header />
      <div css={main}>
        <div css={imgArea}>
          <h1
            css={css`
              opacity: ${loaded ? 1 : 0};
              transition: opacity 1.5s ease-in-out;
            `}
          >
            {MemberList[index].name}
          </h1>
          <img
            css={css`
              width: 70%;
              opacity: ${loaded ? 1 : 0};
              transition: opacity 1.5s ease-in-out;
            `}
            src={imgList[index].img}
            alt=""
          />
        </div>
        <div css={footer}>
          <Link to="/">メニュー</Link>
        </div>
      </div>
    </div>
  );
};

export default Answer;

const main = css`
  padding: 2% 5%;
`;

const imgArea = css`
  padding: 3% 5%;
  width: 60%;
  height: auto;
  margin: 0 auto;
  text-align: center;
  border: 1px solid;
  background-color: #fff;
  font-size: 1vw;
`;

const footer = css`
  text-align: center;
  display: flex;
  justify-content: space-between;
  padding: 2% 5%;
  font-size: 2vw;
`;

const body = css`
  background-image: url(${backImg});
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`
