/** @jsxImportSource @emotion/react */
import React, {useState} from 'react'
import Header from '../components/Header'
import { css } from '@emotion/react'
import dummy from '../images/アキネーター.png'
import { MemberList } from '../components/molecules/Question'
import dummy02 from '../images/figure_reclining_seat.png'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import backImg from '../images/backImg02.jpeg'

function MainPage() {
  let list = MemberList;
  const location = useLocation();
  const index = location.state-1;
  const [hinto01, setHinto01] =useState(0);
  const [hinto02, setHinto02] =useState(0);
  const [hinto03, setHinto03] =useState(0);
  const [a01, setA01] =useState(0);
  const [a02, setA02] =useState(0);
  const [a03, setA03] =useState(0);

  const ToggleHinto01 = () => {
    if(hinto01 === 0){
      setHinto01(1);
      setHinto02(0);
      setHinto03(0);
      setA01(0);
      setA02(0);
      setA03(0);
    }else if(hinto01 ===1){
      setA01(1);
      setA02(0);
      setA03(0);
    }
  }
  const ToggleHinto02 = () => {
    if(hinto02==0){
      setHinto01(0);
      setHinto02(1);
      setHinto03(0);
      setA01(0);
      setA02(0);
      setA03(0);
    }else if(hinto02==1){
      setA01(0);
      setA02(1);
      setA03(0);
    }
  }
  const ToggleHinto03 = () => {
    if(hinto03==0){
      setHinto01(0);
      setHinto02(0);
      setHinto03(1);
      setA01(0);
      setA02(0);
      setA03(0);
    }else if(hinto03==1){
      setA01(0);
      setA02(0);
      setA03(1);
    }
  }

  const HintoForm = ()=> {
    if(hinto01==1){
      return(
        <div css={convAera}>
          <div css={q}>{MemberList[index].q01}</div>
          <HintoFormDetail />
        </div>
      )
    }else if(hinto02==1){
      return (
        <div css={convAera}>
          <div css={q}>{MemberList[index].q02}</div>
          <HintoFormDetail />
        </div>
      );
    }else if(hinto03==1){
      return (
        <div css={convAera}>
          <div css={q}>{MemberList[index].q03}</div>
          <HintoFormDetail />
        </div>
      );
    }else {
      return (
        <div css={textCenter}></div>
      );
    }
  };
  const HintoFormDetail = ()=> {
    console.log(hinto01);
    if(a01===1){
      return(
        <div css={a}>{MemberList[index].a01}</div>
      )
    }else if(a02===1){
      return (
        <div css={a}>{MemberList[index].a02}</div>
      );
    }else if(a03===1){
      return (
          <div css={a}>{MemberList[index].a03}</div>
      );
    }else {
      return (
        <div css={textCenter}></div>
      );
    }
  };
  return (
    <div css={body}>
      <Header />
      {/* <div css={textCenter} >第{list[index].id}問</div> */}
      <div css={mainStyle}>
        <div css={w50}>
          <img css={blockImg} src={dummy} alt="" />
          <HintoForm />
          <img css={block} src={dummy02} alt="" />
        </div>
      </div>
      <div css={footer}>
        <Link to="/" >
          メニュー
        </Link>
        <div css={btnArea}>
          <button css={btn} onClick={ToggleHinto01}>ヒント１</button>
          <button css={btn} onClick={ToggleHinto02}>ヒント２</button>
          <button css={btn} onClick={ToggleHinto03}>ヒント３</button>
        </div>
        <Link
          css={btn02}
          key={index}
          to="/answer"
          state={location}
          >
          正解発表
        </Link>
      </div>
    </div>
  )
}

export default MainPage

const body = css`
  background-image: url(${backImg});
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const btnArea = css`
  display: flex;
  width: 50%;
`;

const mainStyle = css`
  display: flex;
`;

const w50 = css`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-between;
`;

const footer = css`
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin: 5% 0;
  padding-bottom: 5%;
  font-size: 2vw;
`;

const textCenter = css`
  text-align: center;
  font-size: 2vw;
`;

const block = css`
  margin-top: 5%;
  width: 20%;
  display: inline-block;
`

const blockImg = css`
  margin-top: 5%;
  width: 20%;
  display: inline-block;
`

const btn = css`
    width: 60%;
    height: 100%;
    color: #7b6717;
    font-size: 1vw;
    font-weight: bold;
    border-right: 4px solid #cea82c;
    border-bottom: 10px solid #987c1e;
    border-left: 4px solid #ffed8b;
    border-radius: 0;
    background: -webkit-gradient(linear, right top, left top, from(#ffd75b), color-stop(30%, #fff5a0), color-stop(40%, #fffabe), color-stop(50%, #ffffdb), color-stop(70%, #fff5a0), to(#fdd456));
    background: -webkit-linear-gradient(right, #ffd75b 0%, #fff5a0 30%, #fffabe 40%, #ffffdb 50%, #fff5a0 70%, #fdd456 100%);
    background: linear-gradient(-90deg, #ffd75b 0%, #fff5a0 30%, #fffabe 40%, #ffffdb 50%, #fff5a0 70%, #fdd456 100%);
    text-shadow: -1px -1px 1px #ffffd9;
  &:hover {
    margin-top: 3px;
    color: #332d15;
    border-right: 4px solid #cea82c;
    border-bottom: 7px solid #987c1e;
    border-left: 4px solid #ffed8b;
  }
`
const btn02 = css`
    text-decoration: none;
    margin-right: 5%;
    width: auto;
    height: 100%;
    color: #7b6717;
    font-size: 3vw;
    font-weight: bold;
    border-right: 4px solid #cea82c;
    border-bottom: 10px solid #987c1e;
    border-left: 4px solid #ffed8b;
    border-radius: 0;
    background: -webkit-gradient(linear, right top, left top, from(#ffd75b), color-stop(30%, #fff5a0), color-stop(40%, #fffabe), color-stop(50%, #ffffdb), color-stop(70%, #fff5a0), to(#fdd456));
    background: -webkit-linear-gradient(right, #ffd75b 0%, #fff5a0 30%, #fffabe 40%, #ffffdb 50%, #fff5a0 70%, #fdd456 100%);
    background: linear-gradient(-90deg, #ffd75b 0%, #fff5a0 30%, #fffabe 40%, #ffffdb 50%, #fff5a0 70%, #fdd456 100%);
    text-shadow: -1px -1px 1px #ffffd9;
  &:hover {
    margin-top: 3px;
    color: #332d15;
    border-right: 4px solid #cea82c;
    border-bottom: 7px solid #987c1e;
    border-left: 4px solid #ffed8b;
  }
`

const convAera = css`
  margin-top: 5%;
  width: 40%;
`

const q = css`
  position:relative;
  width: auto;
  height:auto;
  background:#E6E6E6;
  padding:2%;
  text-align:left;
  color:#333333;
  font-size:3vw;
  font-weight:bold;
  border-radius:15px;
  -webkit-border-radius:15px;
  -moz-border-radius:15px;
  &::after {
    border: solid transparent;
  content:'';
  height:0;
  width:0;
  pointer-events:none;
  position:absolute;
  border-color: rgba(230, 230, 230, 0);
  border-top-width:5%;
  border-bottom-width:10px;
  border-left-width:30px;
  border-right-width:30px;
  margin-top: -10px;
  border-right-color:#E6E6E6;
  right:100%;
  top:50%;
  }
`

const a = css`
    margin-top: 10%;
    position:relative;
    width: auto;
    height:auto;
    background:#C3DCE6;
    padding:2%;
    text-align:left;
    color:#333333;
    font-size:3vw;
    font-weight:bold;
    border-radius:15px;
    -webkit-border-radius:15px;
    -moz-border-radius:15px;
    &::after {
      border: solid transparent;
      content:'';
      height:0;
      width:0;
      pointer-events:none;
      position:absolute;
      border-color: rgba(195, 220, 230, 0);
      border-top-width:10px;
      border-bottom-width:10px;
      border-left-width:30px;
      border-right-width:30px;
      margin-top: -10px;
      border-left-color:#C3DCE6;
      left:100%;
      top:50%;
    }
`