import React from 'react';
import logo from './logo.svg';
import './App.css';
import Toppage from './pages/TopPage';
import { BrowserRouter, Route ,Routes } from 'react-router-dom';
import MainPage from './pages/MainPage';
import Answer from './pages/Answer';

// ページを 作成したら import すること。
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Toppage />} />
        <Route path="/main" element={<MainPage />} />
        <Route path="/answer" element={<Answer />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
