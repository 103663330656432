/** @jsxImportSource @emotion/react */
import React from 'react'
import { css } from '@emotion/react'
import { Link } from 'react-router-dom';

type member = {
  id: number,
  label: string,
  name: string,
  q01:string,
  q02:string,
  q03:string,
  a01:string,
  a02:string,
  a03:string,
}

// お題定義
export const MemberList : member[] = [
  {
    id: 1,
    label:"メンバー01",
    name: '島田 瞳(しまだひとみ)\nデザインスタジオ,経営企画室',
    q01: "出身地を教えてください！",
    q02: "好きなアニメはなんですか？",
    q03: "小張さんからなんと呼ばれていますか？",
    a01: "山梨県です",
    a02: "セーラームーンです",
    a03: "「ひとみん」です",
  },
  {
    id: 2,
    label:"メンバー02",
    name: '古野 良太(ふるのりょうた)/enginepot代表取締役',
    q01: "小学校時代の部活はなんですか？",
    q02: "今後挑戦したい事業などはありますか？",
    q03: "MMではどんな役回りを担っていますか？",
    a01: "空手です",
    a02: "介護事業の立ち上げ支援",
    a03: "epの代表取締役をしております！",
  },
  {
    id: 3,
    label:"メンバー03",
    name: '坂本 輝(さかもとひかる)/engine Incubation',
    q01: "あだ名を教えてください！",
    q02: "好きなことを教えてください！",
    q03: "昔からの知り合いはいますか？",
    a01: "幼稚園のあだ名は「人造人間しまじろう」でした",
    a02: "健康オタクなので、健康トークできる仲間がいれば非常に嬉しいです",
    a03: "小嶋さんをはじめとしたBEYONDメンバーとReachの馬場さんです",
  },
  {
    id: 4,
    label:"メンバー04",
    name: '芦川 茉奈美(あしかわまなみ)/BEYOND',
    q01: "経歴を教えてください！",
    q02: "好きなことを教えてください！",
    q03: "あだ名を教えてください！",
    a01: "ニトリで働いたことがあります",
    a02: "趣味は吹奏楽（サックスを吹くこと）です",
    a03: "略して「あしまな」戻して「あしだまな」です",
  },
  {
    id: 5,
    label:"メンバー05",
    name: '山中 崇生(やまなかたかお)/経営企画室,SANTA MONICA',
    q01: "趣味を教えてください！",
    q02: "住んだことのある街を教えてください！",
    q03: "あだ名を教えてください",
    a01: "サウナです",
    a02: "京都です",
    a03: "カマキリです",
  },
];

function Question() {
  return (
    <div>
      <div css={btnBox}>
        {MemberList.map((member, index) => (
          <Link
            to="/main"
            css={btn}
            state={member.id}>
            <div key={index}>
            {member.label}
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default Question

const btnArea = css`
  cursor: pointer;
  border: solid 1px;
  background-color: aliceblue;
  width: 50%;
  padding: 2%;
  text-align: center;
  margin-top: 5%;
  text-decoration: none;
  color: black;
`;

const btnBox = css`
  /* width: 50%; */
  display: flex;
  font-size: 2vw;
  flex-wrap: wrap;
`;

const btn = css`
    text-decoration: none;
    margin-top: 5%;
    width: 70%;
    height: 100%;
    color: #7b6717;
    font-size: 2.5vw;
    font-weight: bold;
    border-right: 4px solid #cea82c;
    border-bottom: 10px solid #987c1e;
    border-left: 4px solid #ffed8b;
    border-radius: 0;
    background: -webkit-gradient(linear, right top, left top, from(#ffd75b), color-stop(30%, #fff5a0), color-stop(40%, #fffabe), color-stop(50%, #ffffdb), color-stop(70%, #fff5a0), to(#fdd456));
    background: -webkit-linear-gradient(right, #ffd75b 0%, #fff5a0 30%, #fffabe 40%, #ffffdb 50%, #fff5a0 70%, #fdd456 100%);
    background: linear-gradient(-90deg, #ffd75b 0%, #fff5a0 30%, #fffabe 40%, #ffffdb 50%, #fff5a0 70%, #fdd456 100%);
    text-shadow: -1px -1px 1px #ffffd9;
`
