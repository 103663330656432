/** @jsxImportSource @emotion/react */
import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import Header from '../components/Header'
import { css } from '@emotion/react'
import dummy from '../images/アキネーター.png'
import Question from '../components/molecules/Question'
import backImg from '../images/backImg02.jpeg'


function Toppage() {
  return (
    <div css={body}>
      <Header />
      <div css={mainStyle}>
        <div css={w50}>
          <img css={imgStyle} src={dummy} alt="" />
        </div>
        <div css={w50}>
          <Question />
        </div>
      </div>
    </div>
  )
}

export default Toppage

const body = css`
  padding: 3%;
  background-image: url(${backImg});
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const btnArea = css`
  display: flex;
`;

const mainStyle = css`
  display: flex;
`;

const w50 = css`
  width: 50%;
  text-align: center;
`;

const imgStyle = css`
  width: 80%;
  text-align: center;
`;
